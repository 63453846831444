import React from "react";
import {
	List,
	Datagrid,
	TextField,
	Create,
	SimpleForm,
	NumberInput,
	required,
	minValue,
	NumberField,
	DateField,
	Button,
	useNotify,
} from "react-admin";
import DownloadIcon from "@material-ui/icons/GetApp";
import { gql } from "@apollo/client";
import { useLazyQuery } from "@apollo/client/react";
import LoadMorePagination from "~/components/load-more-pagination.tsx";

function DownloadCodesButton({ record }: any) {
	// console.log("Download codes", record)
	const notify = useNotify();
	const [getGeneration, { loading }] = useLazyQuery(
		gql`
			query ($id: ID!) {
				premadeKitCodeGeneration(id: $id) {
					codes
				}
			}
		`,
		{
			onCompleted(data) {
				const {
					premadeKitCodeGeneration: { codes },
				} = data;
				const content = codes.join("\n");
				const blob = new Blob([content], { type: "text/csv" });
				const url = URL.createObjectURL(blob);
				window.open(url);
			},
			onError(e) {
				notify(e.toString(), { type: "error" });
			},
		},
	);

	return (
		<Button
			type="button"
			onClick={() =>
				getGeneration({
					variables: { id: record.id },
				})
			}
			label="Download codes"
			disabled={loading}
		>
			<DownloadIcon />
		</Button>
	);
}

function PremadeKitCodeGenerationList(props: any) {
	return (
		<List
			{...props}
			title="Premade kit code generations"
			pagination={<LoadMorePagination />}
			sort={{ field: "createdAt", order: "DESC" }}
			exporter={false}
			bulkActionButtons={false}
		>
			<Datagrid>
				<TextField source="id" sortable={false} />
				<NumberField source="numberOfCodes" sortable={false} />
				<DateField source="createdAt" sortable={false} showTime />
				<DownloadCodesButton />
			</Datagrid>
		</List>
	);
}

function PremadeKitCodeGenerationCreate(props: any) {
	return (
		<Create {...props}>
			<SimpleForm>
				<NumberInput
					source="numberOfCodes"
					validate={[required(), minValue(1)]}
				/>
			</SimpleForm>
		</Create>
	);
}

export { PremadeKitCodeGenerationCreate, PremadeKitCodeGenerationList };
