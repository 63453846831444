import type { GraphQLResourceDefinition } from "~/api/graphql-resource-definition.d.ts";
import showImageDetailsItemFragment from "./show-image-details-item-fragment.graphql";
import listImageDetailsItemFragment from "./list-image-details-item-fragment.graphql";

export default {
	GET_LIST: {
		name: "imageDetailsList",
		useListAndCount: true,
		itemFragment: listImageDetailsItemFragment,
	},
	GET_ONE: {
		name: "imageDetailsById",
		itemFragment: showImageDetailsItemFragment,
	},
} as GraphQLResourceDefinition;
