fragment fullInventoryWeights on AdminInventoryWeights {
  id
  userId
  comment
  createdAt
  weights {
    type {
      type
      size
      containerId
      colourId
    }
    weightInGrams
  }
}
