import React from "react";
import { Title, useNotify } from "react-admin";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useMutation } from "@apollo/client/react";
import useInventoryWeights from "~/features/inventory/use-inventory-weights.ts";
import updateInventoryWeightsMutation from "~/api/update-inventory-weights-mutation.graphql";
import type {
	UpdateInventoryWeightsMutation,
	UpdateInventoryWeightsMutationVariables,
} from "~/api/update-inventory-weights-mutation.generated.ts";
import type { AdminInventoryWeights } from "~/api/types.generated.ts";
import InventoryWeightsForm, {
	inventoryWeightsFormValuesToApi,
} from "./inventory-weights-form.tsx";

function InventoryWeights() {
	const { inventoryWeights } = useInventoryWeights();
	const notify = useNotify();

	const [updateInventoryWeights, { loading }] = useMutation<
		UpdateInventoryWeightsMutation,
		UpdateInventoryWeightsMutationVariables
	>(updateInventoryWeightsMutation, {
		onCompleted() {
			notify("Inventory weights updated", { type: "info" });
		},
		onError(error) {
			notify(error.toString(), { type: "error" });
		},
	});

	const onSave = async (rawValues: any) => {
		await updateInventoryWeights({
			variables: inventoryWeightsFormValuesToApi(rawValues),
		});
	};

	// Transform the GraphQL data to match the expected type
	const mappedInventoryWeights = inventoryWeights
		? ({
				...inventoryWeights,
				adminConst: "a",
			} as AdminInventoryWeights)
		: undefined;

	return (
		<div>
			<Title title="Manage inventory weights" />
			<Card>
				<CardContent>
					<InventoryWeightsForm
						actionName="Update Inventory Weights"
						saving={loading}
						onSave={onSave}
						inventoryWeights={mappedInventoryWeights}
					/>
				</CardContent>
			</Card>
		</div>
	);
}

export default InventoryWeights;
