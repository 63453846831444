fragment imageProjects_item on ImageDetails {
    __typename
    id
    userId
    userEmailAddress
    hash
    description
    labels
    imageUrl
    firstSaved {
        projectId
        timestamp
    }
    firstAddedToCart {
        projectId
        timestamp
    }
    firstOrdered {
        projectId
        timestamp
    }
    createdAt
}