mutation updateInventoryWeights(
  $weights: [UpdateinventoryweightsWeights!]!
  $comment: String
) {
  updateInventoryWeights(weights: $weights, comment: $comment) {
    id
    userId
    comment
    createdAt
    weights {
      type {
        type
      }
      weightInGrams
    }
  }
}
