import React from "react";
import type { Record, FunctionFieldProps, ListActionsProps } from "react-admin";
import {
	List,
	Datagrid,
	TextField,
	DateField,
	FunctionField,
	Show,
	SimpleShowLayout,
	ShowButton,
	ListActions,
	ImageField,
} from "react-admin";
import ExportReportButton from "~/components/export-report-button.tsx";
import LoadMorePagination from "~/components/load-more-pagination.tsx";
import ListThumbnailField from "~/components/list-thumbnail-field.tsx";
import createImageDetailsReportMutation from "./create-image-details-report-mutation.graphql";

type LabelsFieldProps = Omit<FunctionFieldProps, "render">;

function LabelsField(props: LabelsFieldProps) {
	return (
		<FunctionField
			{...props}
			render={(record?: Record, source?: string) => {
				if (!record || !source) {
					return null;
				}

				const value = record[source];
				if (!value) {
					return null;
				}
				return value.join(", ");
			}}
		/>
	);
}

LabelsField.defaultProps = {
	addLabel: true,
};

function ImageDetailsListActions(props: ListActionsProps) {
	return (
		<div
			style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
		>
			<ListActions {...props} />
			<ExportReportButton mutation={createImageDetailsReportMutation} />
		</div>
	);
}

function ImageDetailsList(props: any) {
	return (
		<List
			{...props}
			title="Image details"
			bulkActionButtons={false}
			exporter={false}
			sort={{ field: "createdAt", order: "DESC" }}
			pagination={<LoadMorePagination />}
			actions={<ImageDetailsListActions />}
		>
			<Datagrid>
				<TextField source="id" sortable={false} />
				<TextField source="userEmailAddress" sortable={false} />
				<TextField source="description" sortable={false} />
				<LabelsField source="labels" sortable={false} />
				<ListThumbnailField source="imageUrl" label="Image" sortable={false} />
				<DateField source="createdAt" showTime sortable={false} />
				<ShowButton />
			</Datagrid>
		</List>
	);
}

function ImageDetailsShow(props: any) {
	return (
		<Show {...props}>
			<SimpleShowLayout>
				<TextField source="id" />
				<DateField source="createdAt" showTime />
				<TextField source="userId" />
				<TextField source="userEmailAddress" />
				<ImageField source="imageUrl" label="Image" />
				<TextField source="description" />
				<LabelsField source="labels" />
				<TextField source="firstSaved.projectId" label="Saved project" />
				<DateField
					source="firstSaved.timestamp"
					label="Saved project timestamp"
					showTime
				/>
				<TextField source="firstAddedToCart.projectId" label="Cart project" />
				<DateField
					source="firstAddedToCart.timestamp"
					label="Cart project timestamp"
					showTime
				/>
				<TextField source="firstOrdered.projectId" label="Ordered project" />
				<DateField
					source="firstOrdered.timestamp"
					label="Ordered project timestamp"
					showTime
				/>
			</SimpleShowLayout>
		</Show>
	);
}

export { ImageDetailsList, ImageDetailsShow };
