// import writeXlsxFile from 'write-excel-file'
import { write as xlsxWrite, utils as xlsxUtils } from "xlsx";
import { range, reverse } from "lodash-es";
import type { Container, PaletteColour } from "./data.ts";
import { calculateStateTotalCost } from "./calculate-total-cost.ts";
import type {
	AdminInventoryState,
	AdmininventorystateItems,
} from "~/api/types.generated.ts";

const currencyFormat = '"$"#,##0.00;[Red]\\-"$"#,##0.00';
const intFormat = "#,##0";

const emptyBaseItem = {
	quantity: 0,
	cost: "0",
	history: [],
};

function createHistoryItemQuantityCost(
	item: Pick<AdmininventorystateItems, "quantity" | "cost">,
) {
	return [
		{
			value: item.quantity,
			format: intFormat,
		},
		{
			value: item.cost ? parseFloat(item.cost) : 0,
			format: currencyFormat,
		},
		{
			value: item.quantity * (item.cost ? parseFloat(item.cost) : 0),
			format: currencyFormat,
		},
	];
}

const colCharacters = range("A".charCodeAt(0), "Z".charCodeAt(0)).map((i) =>
	String.fromCharCode(i),
);

function createHistoryLines(
	items: readonly Omit<AdmininventorystateItems, "type">[],
) {
	const histories = items.map((p) => {
		const nonZeroHistories = p.history.filter((h) => h.quantity !== 0);
		if (nonZeroHistories.length === 0) {
			return [
				{
					cost: "0",
					quantity: 0,
				},
			];
		}
		return nonZeroHistories;
	});
	if (histories.length === 0) {
		return [];
	}

	let totalQuantity = 0;
	let weightedCostSum = 0;
	let totalCost = 0;

	histories.flat().forEach((historyItem) => {
		const quantity = historyItem.quantity;
		const cost = historyItem.cost ? parseFloat(historyItem.cost) : 0;
		totalQuantity += quantity;
		weightedCostSum += quantity * cost;
		totalCost += quantity * cost;
	});

	const avgUnitCost = totalQuantity ? weightedCostSum / totalQuantity : 0;

	const totalRow = [
		{
			value: totalQuantity,
			format: intFormat,
		},
		{
			value: avgUnitCost,
			format: currencyFormat,
		},
		{
			value: totalCost,
			format: currencyFormat,
		},
	];

	const maxHistories = Math.max(...histories.map((h) => h.length));
	const historyRows = range(0, maxHistories).map((i) => {
		return histories.flatMap((historySet) => {
			const historyItem = historySet[i];
			return historyItem
				? createHistoryItemQuantityCost(historyItem)
				: [null, null, null];
		});
	});

	const result = [...totalRow, ...reverse(historyRows).flat()];
	return [result];
}

function createPaperDataLines(state: AdminInventoryState) {
	const paperItems = [
		{ label: "Paper-A5", type: "paperA5" },
		{ label: "Paper-A4", type: "paperA4" },
		{ label: "Paper-A3", type: "paperA3" },
		{ label: "Paper-Leter", type: "paperLetter" },
	];

	const paperRows = paperItems.flatMap(({ label, type }) => {
		const item = state.items.find((i) => i.type.type === type) ?? emptyBaseItem;

		const historyLines = createHistoryLines([item]);
		return historyLines.map((line, i) => [
			i === 0
				? {
						value: label,
						fontWeight: "bold" as const,
					}
				: null,
			...line,
		]);
	});

	return paperRows;
}

function createMiscDataLines(state: AdminInventoryState) {
	const miscItems = [
		{ label: "Brick separators", type: "brickSeparators" },
		{ label: "Packaging", type: "packaging" },
		{ label: "Small flatpack box", type: "smallFlatpackBox" },
		{ label: "Logo tile", type: "logoTile" },
		{ label: "Padded envelope", type: "paddedEnvelope" },
		{ label: "Single box", type: "singleBox" },
		{ label: "Double box", type: "doubleBox" },
		{ label: "Triple box", type: "tripleBox" },
	];

	const miscRows = miscItems.flatMap(({ label, type }) => {
		const item = state.items.find((i) => i.type.type === type) ?? emptyBaseItem;

		const historyLines = createHistoryLines([item]);
		return historyLines.map((line, i) => [
			i === 0
				? {
						value: label,
						fontWeight: "bold" as const,
					}
				: null,
			...line,
		]);
	});

	return miscRows;
}

function createSpreadsheet(
	colours: readonly PaletteColour[],
	containers: readonly Container[],
	state: AdminInventoryState,
) {
	//Need to know max number of FIFO entries to create appropriate headers
	const maxHistoryLength = Math.max(
		...state.items.map(
			(item) => item.history.filter((entry) => entry.quantity !== 0).length,
		),
	);

	const headerRows = [
		[
			{
				value: "Item Name",
				fontWeight: "bold" as const,
			},
			{
				value: "Quantity",
				fontWeight: "bold" as const,
			},
			{
				value: "Avg Unit Cost",
				fontWeight: "bold" as const,
			},
			{
				value: "Total Cost",
				fontWeight: "bold" as const,
			},
			...Array.from({ length: maxHistoryLength }, (_, i) => [
				{
					value: `Quantity ${i + 1}`,
					fontWeight: "bold" as const,
				},
				{
					value: `Unit Cost ${i + 1}`,
					fontWeight: "bold" as const,
				},
				{
					value: `Total ${i + 1}`,
					fontWeight: "bold" as const,
				},
			]).flat(),
		],
	];

	//Historically containers where shown vertically in the export against the one colour id
	//Even though only one conatiner in use at the moment, update to a sku based view where containers
	//will be shown against each line per colour
	const colourRows = colours.flatMap((colour) =>
		containers.flatMap((c) => {
			const containerItem = state.items.find(
				(sc) =>
					sc.type.type === "container" &&
					sc.type.containerId === c.identifier &&
					sc.type.colourId?.toString() === colour.id.toString(),
			);

			const item = containerItem ? containerItem : emptyBaseItem;

			const colourLines = createHistoryLines([item]);

			return colourLines.map((line, i) => [
				i === 0
					? {
							value: `${colour.id}-${c.identifier}`,
							fontWeight: "bold" as const,
						}
					: null,
				...line,
			]);
		}),
	);

	const baseplateRows = state.items
		.filter((i) => i.type.type === "baseplate")
		.flatMap((b) => {
			const baseplateLines = createHistoryLines([b]);

			return baseplateLines.map((line, i) => [
				i === 0
					? {
							value: `Baseplate-${b.type.size}x${b.type.size}`,
							fontWeight: "bold" as const,
						}
					: null,
				...line,
			]);
		});

	const data = [
		...headerRows,
		...colourRows,
		...baseplateRows,
		...createPaperDataLines(state),
		...createMiscDataLines(state),
		[],
		[
			{
				value: "Total cost",
				fontWeight: "bold" as const,
			},
			{
				value: "",
			},
			{
				value: "",
			},
			{
				value: calculateStateTotalCost(state),
				format: currencyFormat,
			},
		],
	];

	const ws = xlsxUtils.aoa_to_sheet(
		data.map((r) => r.map((c) => c?.value)),
		{},
	);

	data.forEach((row, y) =>
		row.forEach((cell, x) => {
			if (typeof cell !== "object" || !cell) {
				return;
			}

			const cellName = `${colCharacters[x]}${y + 1}`;
			const sheetCell = ws[cellName];

			if ("fontWeight" in cell && cell.fontWeight === "bold") {
				// Only available in pro version :(
				// sheetCell.s = {
				//   ...sheetCell.s,
				//   font: {
				//     bold: true
				//   }
				// };
			}

			if ("format" in cell) {
				sheetCell.z = cell.format;
			}
		}),
	);

	const wb = xlsxUtils.book_new();
	xlsxUtils.book_append_sheet(wb, ws);
	const out = xlsxWrite(wb, {
		type: "buffer",
		Props: {
			Title: "Report",
			// Subject: string;
			Author: "Brick me admin",
			// Company?: string;
			// CreatedDate?: Date;
		},
	});

	//   const blob = await writeXlsxFile(data, {});
	//   const arrayBuffer = await (blob as Blob).arrayBuffer();
	//   return arrayBuffer;

	return out;
}

export default createSpreadsheet;
