import React from "react";
import type { ListActionsProps } from "react-admin";
import {
	FileField,
	ImageField,
	List,
	Datagrid,
	TextField,
	ShowButton,
	Show,
	DateField,
	SimpleShowLayout,
	UrlField,
	TextInput,
	Filter,
	ListActions,
	DateInput,
	DeleteButton,
	Button,
	useNotify,
	useRedirect,
} from "react-admin";
import { useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import UserSummaryField from "~/components/user-summary-field.tsx";
import PictureSummaryField from "~/components/picture-summary-field.tsx";
import ListThumbnailField from "~/components/list-thumbnail-field.tsx";
import LoadMorePagination from "~/components/load-more-pagination.tsx";
import ChangeUserButton from "~/components/change-user-button.tsx";
import userSummaryFragment from "~/api/user-summary-fragment.ts";
import ExportReportButton from "~/components/export-report-button.tsx";

function CartProjectFilter(props: any) {
	return (
		<Filter {...props}>
			<TextInput label="User email address" source="emailAddress" alwaysOn />
			<DateInput label="From date" source="fromDate" />
			<DateInput label="To date" source="toDate" />
		</Filter>
	);
}

function ChangeCartProjectUserButton({ record }: any) {
	return (
		<ChangeUserButton
			record={record}
			mutation={gql`
				mutation changeCartProjectUserMutation($id: ID!, $email: String!) {
					changeCartProjectUser(id: $id, email: $email) {
						__typename
						id
						user {
							__typename
							id
							...userSummary
						}
					}
				}
				${userSummaryFragment}
			`}
			useCopy={false}
		/>
	);
}

type OrderCartProjectVariables = {
	readonly id: string;
};

function OrderCartProjectButton({ record }: any) {
	const notify = useNotify();
	const redirect = useRedirect();
	const [orderCartProject, { loading }] = useMutation<
		unknown,
		OrderCartProjectVariables
	>(
		gql`
			mutation orderCartProjectMutation($id: ID!) {
				orderCartProject(id: $id) {
					__typename
					id
				}
			}
		`,
		{
			variables: { id: record.id },
			onError(e) {
				notify(e.toString(), { type: "error" });
			},
			onCompleted() {
				notify("Project has been ordered", "info");
				redirect("list", "/orderedProjects");
				// data.orderCartProject
			},
		},
	);
	return (
		<Button
			type="button"
			onClick={() => orderCartProject()}
			label={loading ? "Ordering" : "Order"}
			disabled={loading}
		>
			<ShoppingCart />
		</Button>
	);
}

function CartProjectListActions(props: ListActionsProps) {
	return (
		<div
			style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
		>
			<ListActions {...props} />
			<ExportReportButton
				mutation={gql`
					mutation startCartProjectsReportMutation {
						createCartProjectsReport
					}
				`}
			/>
		</div>
	);
}

function CartProjectList(props: any) {
	return (
		<List
			{...props}
			filters={<CartProjectFilter />}
			title="Cart projects"
			bulkActionButtons={false}
			exporter={false}
			sort={{ field: "updatedAt", order: "DESC" }}
			pagination={<LoadMorePagination />}
			actions={<CartProjectListActions />}
		>
			<Datagrid>
				<TextField source="id" sortable={false} />
				<TextField source="user.emailAddress" label="User" sortable={false} />
				<DateField source="updatedAt" showTime sortable />
				<ListThumbnailField source="mediumImageUrl" sortable={false} />
				<ShowButton />
				<OrderCartProjectButton />
				<ChangeCartProjectUserButton />
				<DeleteButton undoable={false} />
			</Datagrid>
		</List>
	);
}

function CartProjectShow(props: any) {
	return (
		<Show {...props}>
			<SimpleShowLayout>
				<TextField source="id" />
				<TextField source="shopifyProductId" />
				<UrlField
					source="shopifyAdminProductUrl"
					target="_blank"
					rel="noreferrer noopener"
				/>
				<DateField source="updatedAt" showTime />
				<FileField
					source="sourceImageUrl"
					title="Source image"
					target="_blank"
					rel="noopener noreferrer"
				/>
				<ImageField source="mediumImageUrl" />
				<PictureSummaryField label="Picture" source="picture" />
				<UserSummaryField label="User" source="user" />
			</SimpleShowLayout>
		</Show>
	);
}

export { CartProjectList, CartProjectShow };
