import React from "react";
import "./skeleton.css";

type SkeletonProps = {
	width?: string | number;
	height?: string | number;
	variant?: "text" | "circle" | "rectangular";
	style?: React.CSSProperties;
	className?: string;
};

const Skeleton: React.FC<SkeletonProps> = ({
	width = "100%",
	height = "1em",
	variant = "text",
	style,
	className = "",
}) => {
	const baseStyle: React.CSSProperties = {
		width: typeof width === "number" ? `${width}px` : width,
		height: typeof height === "number" ? `${height}px` : height,
		borderRadius: variant === "circle" ? "50%" : "4px",
		...style,
	};

	return <div className={`skeleton ${className}`} style={baseStyle}></div>;
};

export default Skeleton;
