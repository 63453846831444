mutation updateInstructionSettings(
  $catalogueImage: String!
  $catalogueEnabled: Boolean!
) {
  updateInstructionSettings(
    catalogueImage: $catalogueImage
    catalogueEnabled: $catalogueEnabled
  ) {
    catalogueImage
    catalogueEnabled
    createdAt
    updatedAt
  }
}