import React, { useState, useEffect } from "react";
import GetApp from "@material-ui/icons/GetApp";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { formatMoneyRounded } from "~/utils/money.ts";
import { useInventoryTypesBlacklist } from "~/hooks/inventory.tsx";
import type { AdminInventoryState } from "~/api/types.generated.ts";
import ColourContainerTable from "./colour-container-table.tsx";
import { formatInventoryStateTotalCost } from "./format-inventory-state-item.ts";
import type { Container, PaletteColour } from "./data.ts";
import createSpreadsheet from "./create-spreadsheet.ts";
import { calculateStateTotalCost } from "./calculate-total-cost.ts";

type InventoryStateReportProps = {
	readonly baseFilename: string;
	readonly state: AdminInventoryState;
	readonly colours: readonly PaletteColour[];
	readonly containers: readonly Container[];
};

type ExportSpreadsheet = {
	readonly href: string;
	readonly filename: string;
};

function InventoryStateReport({
	baseFilename,
	state,
	colours,
	containers,
}: InventoryStateReportProps) {
	const { allowInventoryType } = useInventoryTypesBlacklist();
	const [exportSpreadsheet, setExportSpreadsheet] = useState<
		ExportSpreadsheet | undefined
	>(undefined);
	useEffect(() => {
		setExportSpreadsheet(undefined);
		if (colours.length === 0 || containers.length === 0) {
			return undefined;
		}

		let waiting = true;
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		(async () => {
			const buffer = await createSpreadsheet(colours, containers, state);
			const blob = new Blob([buffer], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			});
			const href = URL.createObjectURL(blob);
			if (waiting) {
				setExportSpreadsheet({
					href,
					filename: `${baseFilename}.xlsx`,
				});
			}
		})();
		return () => {
			waiting = false;
		};
	}, [state, baseFilename, containers, colours]);

	if (colours.length === 0 || containers.length === 0) {
		return <div>Loading...</div>;
	}

	return (
		<div>
			{exportSpreadsheet && (
				<div style={{ display: "flex", justifyContent: "flex-end" }}>
					<Button
						color="primary"
						variant="text"
						href={exportSpreadsheet.href}
						download={exportSpreadsheet.filename}
					>
						<GetApp />
						Export
					</Button>
				</div>
			)}
			<ColourContainerTable
				colours={colours}
				containers={containers}
				renderCell={(colour, container) =>
					formatInventoryStateTotalCost(
						state.items.find(
							(i) =>
								i.type.type === "container" &&
								i.type.containerId === container.identifier &&
								i.type.colourId?.toString() === colour.id.toString(),
						),
					)
				}
			/>
			<div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
				<Typography component="h3">Baseplates</Typography>
				<div
					style={{ flex: 1, display: "flex", flexDirection: "row", gap: 20 }}
				>
					{state.items
						.filter((i) => i.type.type === "baseplate")
						.map((baseplate) => (
							<div key={baseplate.type.size}>
								<Typography component="h4">
									{baseplate.type.size}x{baseplate.type.size}
								</Typography>
								<Typography component="p">
									{formatInventoryStateTotalCost(baseplate)}
								</Typography>
							</div>
						))}
				</div>
				<Typography component="h3">Paper</Typography>
				<div
					style={{ flex: 1, display: "flex", flexDirection: "row", gap: 20 }}
				>
					{allowInventoryType({ type: "paperA5" }) && (
						<div>
							<Typography component="h4">A5</Typography>
							<Typography component="p">
								{formatInventoryStateTotalCost(
									state.items.find((i) => i.type.type === "paperA5"),
								)}
							</Typography>
						</div>
					)}
					{allowInventoryType({ type: "paperA4" }) && (
						<div>
							<Typography component="h4">A4</Typography>
							<Typography component="p">
								{formatInventoryStateTotalCost(
									state.items.find((i) => i.type.type === "paperA4"),
								)}
							</Typography>
						</div>
					)}
					{allowInventoryType({ type: "paperLetter" }) && (
						<div>
							<Typography component="h4">Letter</Typography>
							<Typography component="p">
								{formatInventoryStateTotalCost(
									state.items.find((i) => i.type.type === "paperLetter"),
								)}
							</Typography>
						</div>
					)}
					{allowInventoryType({ type: "paperA3" }) && (
						<div>
							<Typography component="h4">A3</Typography>
							<Typography component="p">
								{formatInventoryStateTotalCost(
									state.items.find((i) => i.type.type === "paperA3"),
								)}
							</Typography>
						</div>
					)}
				</div>
				<Typography component="h3">&nbsp;</Typography>
				<div
					style={{ flex: 1, display: "flex", flexDirection: "row", gap: 20 }}
				>
					<div>
						<Typography component="h4">Brick separators</Typography>
						<Typography component="p">
							{formatInventoryStateTotalCost(
								state.items.find((i) => i.type.type === "brickSeparators"),
							)}
						</Typography>
					</div>
					<div>
						<Typography component="h4">Packaging</Typography>
						<Typography component="p">
							{formatInventoryStateTotalCost(
								state.items.find((i) => i.type.type === "packaging"),
							)}
						</Typography>
					</div>
					<div>
						<Typography component="h4">Small flatpack box</Typography>
						<Typography component="p">
							{formatInventoryStateTotalCost(
								state.items.find((i) => i.type.type === "smallFlatpackBox"),
							)}
						</Typography>
					</div>
					<div>
						<Typography component="h4">Logo tile</Typography>
						<Typography component="p">
							{formatInventoryStateTotalCost(
								state.items.find((i) => i.type.type === "logoTile"),
							)}
						</Typography>
					</div>
				</div>
				<Typography component="h3">&nbsp;</Typography>
				<div
					style={{ flex: 1, display: "flex", flexDirection: "row", gap: 20 }}
				>
					{allowInventoryType({ type: "paddedEnvelope" }) && (
						<div>
							<Typography component="h4">Padded envelope</Typography>
							<Typography component="p">
								{formatInventoryStateTotalCost(
									state.items.find((i) => i.type.type === "paddedEnvelope"),
								)}
							</Typography>
						</div>
					)}
					{allowInventoryType({ type: "singleBox" }) && (
						<div>
							<Typography component="h4">Single box</Typography>
							<Typography component="p">
								{formatInventoryStateTotalCost(
									state.items.find((i) => i.type.type === "singleBox"),
								)}
							</Typography>
						</div>
					)}
					{allowInventoryType({ type: "doubleBox" }) && (
						<div>
							<Typography component="h4">Double box</Typography>
							<Typography component="p">
								{formatInventoryStateTotalCost(
									state.items.find((i) => i.type.type === "doubleBox"),
								)}
							</Typography>
						</div>
					)}
					{allowInventoryType({ type: "tripleBox" }) && (
						<div>
							<Typography component="h4">Triple box</Typography>
							<Typography component="p">
								{formatInventoryStateTotalCost(
									state.items.find((i) => i.type.type === "tripleBox"),
								)}
							</Typography>
						</div>
					)}
				</div>
			</div>
			<div>
				<Typography component="h3">Total cost</Typography>
				<Typography component="p">
					{formatMoneyRounded(calculateStateTotalCost(state).toString())}
				</Typography>
			</div>
		</div>
	);
}

export type { InventoryStateReportProps };
export default InventoryStateReport;
