import React, { useState, useEffect, useMemo } from "react";
import type { DataProvider } from "react-admin";
import { Admin, Resource } from "react-admin";
import buildGraphQLProvider from "ra-data-graphql";
import {
	ApolloClient,
	createHttpLink,
	InMemoryCache,
	from,
} from "@apollo/client/core";
import { ApolloProvider } from "@apollo/client/react";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";
import { Settings } from "luxon";
import {
	Assignment,
	AssignmentTurnedIn,
	AssignmentIndSharp,
	People,
	PhotoAlbum,
	Photo,
	Palette,
	LocalAtm,
	LibraryBooks,
	Assessment,
	Subject,
	Tune,
	Brush,
	PhotoLibrary,
	FormatListBulleted as BulkInventoryItemIcon,
	Scanner as BulkInventoryScanIcon,
	DeveloperBoard as DeveloperBoardIcon,
	CreateNewFolder as CreateNewFolderIcon,
	FitnessCenterOutlined,
} from "@material-ui/icons";
import { Grid, CircularProgress } from "@material-ui/core";
import {
	SavedProjectList,
	SavedProjectShow,
} from "~/features/saved-project/components.tsx";
import {
	BrickArtDesignList,
	BrickArtDesignShow,
} from "~/features/brick-art-design/components.tsx";
import { ReportList } from "~/features/report/components.tsx";
import {
	CartProjectList,
	CartProjectShow,
} from "~/features/cart-project/components.tsx";
import { Dashboard } from "~/features/dashboard/index.tsx";
import { UserShow, UserList } from "~/features/user/components.tsx";
import buildQuery from "~/api/build-query.ts";
import {
	OrderedProjectList,
	OrderedProjectShow,
} from "~/features/ordered-project/components.tsx";
import {
	PicToBrickCreate,
	PicToBrickEdit,
	PicToBrickList,
} from "~/features/pic-to-brick/pic-components.tsx";
import {
	PicToBrickCategoryCreate,
	PicToBrickCategoryEdit,
	PicToBrickCategoryList,
} from "~/features/pic-to-brick/category-components.tsx";
import {
	PaletteColourCreate,
	PaletteColourEdit,
	PaletteColourList,
} from "~/features/palette-colour/components.tsx";
import { EditPricingModel } from "~/features/pricing-model/components.tsx";
import ManageInventory from "~/features/inventory/manage-inventory.tsx";
import InventoryHistory from "~/features/inventory/inventory-history.tsx";
import InventorySettings from "~/features/inventory/inventory-settings.tsx";
import InventoryItemShow from "~/features/inventory/inventory-item-show.tsx";
import InventoryReport from "~/features/inventory/inventory-report.tsx";
import { PremadeKitCodeList } from "~/features/premade-kits/code-components.tsx";
import {
	PremadeKitCodeGenerationCreate,
	PremadeKitCodeGenerationList,
} from "~/features/premade-kits/code-generation-components.tsx";
import InventoryReportLegacy from "~/features/inventory-legacy/inventory-report.tsx";
import InventoryItemShowLegacy from "~/features/inventory-legacy/inventory-item-show.tsx";
import InventoryHistoryLegacy from "~/features/inventory-legacy/inventory-history.tsx";
import {
	BulkInventoryItemList,
	CreateBulkInventoryItem,
} from "~/features/inventory-bulk/bulk-inventory-item-components.tsx";
import { BulkInventoryScanList } from "~/features/inventory-bulk/bulk-inventory-scan-components.tsx";
import { CreateBulkInventoryScan } from "~/features/inventory-bulk/scan-create-components.tsx";
import { ReviewBulkInventoryScan } from "~/features/inventory-bulk/scan-review-components.tsx";
import { ShopifyUrlProvider } from "~/hooks/shopify-url.tsx";
import { MediaBaseUrlProvider } from "~/hooks/media-base-url.tsx";
import { BasePlateSizesProvider } from "~/hooks/base-plate-sizes.tsx";
import { InventoryTypesBlacklistProvider } from "~/hooks/inventory.tsx";
import { isFeatureEnabled } from "~/utils/feature-flags.ts";
import { createAuthProvider } from "./auth-provider.ts";
import InventoryWeights from "~/features/inventory-weights/inventory-weights.tsx";
import {
	ImageDetailsList,
	ImageDetailsShow,
} from "~/features/image-details/components.tsx";
import { EditInstructionSettings } from "~/features/instruction-settings/components.tsx";

type AppProps = {
	readonly apiUrl: string;
	readonly shopifyUrl: string;
	readonly mediaBaseUrl: string;
	readonly userPoolId: string;
	readonly userPoolClientId: string;
	readonly premadeKitsEnabled: boolean;
	readonly inventoryTypesBlacklist: readonly Record<string, unknown>[];
	readonly basePlateSizes: readonly number[];
	readonly inventoryBasePlateSizes: readonly number[];
};

function App({
	apiUrl,
	shopifyUrl,
	mediaBaseUrl,
	basePlateSizes,
	inventoryTypesBlacklist,
	inventoryBasePlateSizes,
	premadeKitsEnabled,
	userPoolId,
	userPoolClientId,
}: AppProps) {
	const [dataProvider, setDataProvider] = useState<DataProvider | undefined>(
		undefined,
	);

	const authProvider = useMemo(
		() =>
			createAuthProvider({
				userPoolId,
				userPoolClientId,
			}),
		[userPoolId, userPoolClientId],
	);
	const apolloClient = useMemo(() => {
		const httpLink = createHttpLink({
			uri: apiUrl,
			headers: {
				"X-Timezone": Settings.defaultZoneName,
			},
		});
		const authLink = setContext(async (_, { headers }) => {
			if (!authProvider.getIdentity) {
				throw new Error("No way to get identity");
			}

			const adminUser = await authProvider.getIdentity();
			if (!adminUser) {
				return { headers };
			}
			return {
				headers: {
					...headers,
					Authorization: adminUser.token,
				},
			};
		});

		return new ApolloClient({
			link: from([new RetryLink(), authLink, httpLink]),
			cache: new InMemoryCache(),
			defaultOptions: {
				watchQuery: {
					fetchPolicy: "no-cache",
				},
				query: {
					fetchPolicy: "no-cache",
				},
			},
		});
	}, [apiUrl, authProvider]);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		(async () => {
			const provider = await buildGraphQLProvider({
				client: apolloClient as any,
				// Too difficult to keep up to date
				// introspection: { schema } as any,
				buildQuery,
			});
			// This is confusing to me - not sure why this needs to be a function, but buildQuery doesn't
			// work properly without it
			setDataProvider(() => provider);
		})();
	}, [apolloClient]);

	if (!dataProvider) {
		return (
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<CircularProgress />
				</Grid>
			</Grid>
		);
	}

	return (
		<ShopifyUrlProvider shopifyUrl={shopifyUrl}>
			<MediaBaseUrlProvider mediaBaseUrl={mediaBaseUrl}>
				<ApolloProvider client={apolloClient}>
					<BasePlateSizesProvider
						basePlateSizes={basePlateSizes}
						inventoryBasePlateSizes={inventoryBasePlateSizes}
					>
						<InventoryTypesBlacklistProvider
							inventoryTypesBlacklist={inventoryTypesBlacklist}
						>
							<Admin
								title="Brick.me Admin"
								authProvider={authProvider}
								dataProvider={dataProvider}
								dashboard={Dashboard}
							>
								{(permissions) =>
									[
										<Resource
											key="users"
											name="users"
											list={UserList}
											show={UserShow}
											icon={People}
										/>,
										<Resource
											key="savedProjects"
											name="savedProjects"
											list={SavedProjectList}
											show={SavedProjectShow}
											icon={AssignmentIndSharp}
											options={{ label: "Saved projects" }}
										/>,
										<Resource
											key="cartProjects"
											name="cartProjects"
											list={CartProjectList}
											show={CartProjectShow}
											icon={Assignment}
											options={{ label: "Cart projects" }}
										/>,
										<Resource
											key="orderedProjects"
											name="orderedProjects"
											list={OrderedProjectList}
											show={OrderedProjectShow}
											icon={AssignmentTurnedIn}
											options={{ label: "Ordered projects" }}
										/>,
										<Resource
											key="picToBrickCategories"
											name="picToBrickCategories"
											list={PicToBrickCategoryList}
											edit={PicToBrickCategoryEdit}
											create={PicToBrickCategoryCreate}
											icon={PhotoAlbum}
											options={{ label: "Pic to brick categories" }}
										/>,
										<Resource
											key="picsToBrick"
											name="picsToBrick"
											list={PicToBrickList}
											edit={PicToBrickEdit}
											create={PicToBrickCreate}
											icon={Photo}
											options={{ label: "Pics to brick" }}
										/>,
										<Resource
											key="brickArtDesigns"
											name="brickArtDesigns"
											list={BrickArtDesignList}
											show={BrickArtDesignShow}
											icon={Brush}
											options={{ label: "Brick art designs" }}
										/>,
										<Resource
											key="imageDetails"
											name="imageDetails"
											list={ImageDetailsList}
											show={ImageDetailsShow}
											icon={PhotoLibrary}
											options={{ label: "Image details" }}
										/>,
										<Resource
											key="paletteColours"
											name="paletteColours"
											list={PaletteColourList}
											create={PaletteColourCreate}
											edit={PaletteColourEdit}
											icon={Palette}
											options={{ label: "Palette colours" }}
										/>,
										<Resource
											key="pricingModel"
											name="pricingModel"
											list={EditPricingModel}
											icon={LocalAtm}
											options={{ label: "Pricing model" }}
										/>,
										<Resource
											key="instructionSettings"
											name="instructionSettings"
											list={EditInstructionSettings}
											icon={Tune}
											options={{ label: "Instruction settings" }}
										/>,
										<Resource
											key="manageInventory"
											name="manageInventory"
											list={ManageInventory}
											icon={Subject}
											options={{ label: "Manage inventory" }}
										/>,
										<Resource
											key="inventoryWeights"
											name="inventoryWeights"
											list={InventoryWeights}
											icon={FitnessCenterOutlined}
											options={{ label: "Inventory weights" }}
										/>,
										<Resource
											key="inventoryItems"
											name="inventoryItems"
											list={InventoryHistory}
											show={InventoryItemShow}
											icon={LibraryBooks}
											options={{ label: "Inventory history" }}
										/>,
										<Resource
											key="inventoryReports"
											name="inventoryReports"
											list={InventoryReport}
											icon={Assessment}
											options={{ label: "Inventory report" }}
										/>,
										<Resource
											key="inventorySettings"
											name="inventorySettings"
											list={InventorySettings}
											icon={Tune}
											options={{ label: "Inventory settings" }}
										/>,
										<Resource
											key="inventoryItemsLegacy"
											name="inventoryItemsLegacy"
											list={InventoryHistoryLegacy}
											show={InventoryItemShowLegacy}
											icon={LibraryBooks}
											options={{ label: "Inventory history - legacy" }}
										/>,
										<Resource
											key="inventoryReportsLegacy"
											name="inventoryReportsLegacy"
											list={InventoryReportLegacy}
											icon={Assessment}
											options={{ label: "Inventory reports - legacy" }}
										/>,
										...(isFeatureEnabled("bulkInventory")
											? [
													<Resource
														key="bulkInventoryItems"
														name="bulkInventoryItems"
														create={CreateBulkInventoryItem}
														list={BulkInventoryItemList}
														icon={BulkInventoryItemIcon}
														options={{
															label: "Bulk inventory items",
															permission: "inventory",
														}}
													/>,
													<Resource
														key="bulkInventoryCounts"
														name="bulkInventoryCounts"
														create={CreateBulkInventoryScan}
														edit={ReviewBulkInventoryScan}
														list={BulkInventoryScanList}
														icon={BulkInventoryScanIcon}
														options={{
															label: "Bulk inventory counts",
															permission: "inventory",
														}}
													/>,
												]
											: []),
										<Resource
											key="reports"
											name="reports"
											list={ReportList}
											icon={Assessment}
											options={{ label: "Reports" }}
										/>,
										...(premadeKitsEnabled
											? [
													<Resource
														key="premadeKitCodeGenerations"
														name="premadeKitCodeGenerations"
														create={PremadeKitCodeGenerationCreate}
														list={PremadeKitCodeGenerationList}
														icon={CreateNewFolderIcon}
														options={{ label: "Premade kit code generations" }}
													/>,
													<Resource
														key="premadeKitCodes"
														name="premadeKitCodes"
														list={PremadeKitCodeList}
														icon={DeveloperBoardIcon}
														options={{ label: "Premade kit codes" }}
													/>,
												]
											: []),
									].filter((r) => {
										// Loading
										if (permissions.length === 0 || permissions.includes("*")) {
											return true;
										}

										const permission = r.props.options?.permission;
										if (!permission) {
											return permissions.includes("*");
										}
										return permissions.includes(permission);
									})
								}
							</Admin>
						</InventoryTypesBlacklistProvider>
					</BasePlateSizesProvider>
				</ApolloProvider>
			</MediaBaseUrlProvider>
		</ShopifyUrlProvider>
	);
}

export default App;
