import type { AuthProvider, UserIdentity } from "ra-core";
import type { CognitoUserSession } from "amazon-cognito-identity-js";
import {
	AuthenticationDetails,
	CognitoUser,
	CognitoUserPool,
} from "amazon-cognito-identity-js";

type AdminUser = UserIdentity & {
	readonly token: string;
	readonly fullName: string;
	readonly group: "super-admin" | "inventory";
};

type AuthProviderOptions = {
	readonly userPoolId: string;
	readonly userPoolClientId: string;
};

function createAuthProvider({
	userPoolId,
	userPoolClientId,
}: AuthProviderOptions): AuthProvider {
	const userPool = new CognitoUserPool({
		UserPoolId: userPoolId,
		ClientId: userPoolClientId,
	});
	let cognitoUser: CognitoUser | undefined;

	async function sessionToAdminUser(
		user: CognitoUser,
		session: CognitoUserSession,
	) {
		const accessToken = session.getAccessToken().getJwtToken();
		const idToken = session.getIdToken().getJwtToken();
		const [, payload] = idToken.split(".");
		const idTokenPayload = JSON.parse(atob(payload));
		return new Promise<AdminUser>((resolve, reject) => {
			user.getUserAttributes(function (err, attributes) {
				if (err) {
					reject(err);
					return;
				}

				const firstName = attributes?.find(
					(a) => a.Name === "given_name",
				)?.Value;
				const lastName = attributes?.find(
					(a) => a.Name === "family_name",
				)?.Value;
				const emailAddress = attributes?.find((a) => a.Name === "email")?.Value;
				const group = idTokenPayload["cognito:groups"].some((g: string) =>
					g.includes("SuperAdmin"),
				)
					? "super-admin"
					: "inventory";
				resolve({
					id: user.getUsername(),
					token: accessToken,
					fullName:
						firstName && lastName
							? [firstName, lastName].join(" ")
							: (emailAddress ?? user.getUsername()),
					group,
				});
			});
		});
	}

	async function getIdentity() {
		return new Promise<AdminUser>((resolve, reject) => {
			cognitoUser = userPool.getCurrentUser() ?? undefined;
			if (!cognitoUser) {
				reject(new Error("User not authenticated"));
				return;
			}

			cognitoUser.getSession(async function (
				err: Error | null,
				session: CognitoUserSession | null,
			) {
				if (err) {
					reject(err);
					return;
				}
				if (!session) {
					reject(new Error("No session"));
					return;
				}

				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				const adminUser = await sessionToAdminUser(cognitoUser!, session);
				localStorage.setItem("authGroup", adminUser.group);
				resolve(adminUser);
			});
		});
	}

	// Re: localStorage use. See:
	// https://marmelab.com/react-admin/doc/4.16/AuthProviderWriting.html
	// Without it some funky stuff happens
	return {
		// called when the user attempts to log in
		async login({ username, password }: any) {
			return new Promise<AdminUser>((resolve, reject) => {
				cognitoUser = new CognitoUser({
					Username: username,
					Pool: userPool,
				});
				cognitoUser.authenticateUser(
					new AuthenticationDetails({
						Username: username,
						Password: password,
					}),
					{
						onSuccess: async function (session) {
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							const adminUser = await sessionToAdminUser(cognitoUser!, session);
							localStorage.setItem("authGroup", adminUser.group);
							// localStorage.setItem(
							// 	"permissions",
							// 	JSON.stringify(adminUser.permissions),
							// );
							// Wait a little so cognitoUser.authenticateUser finishes
							setTimeout(() => {
								resolve(adminUser);
							}, 500);
						},
						onFailure: reject,
					},
				);
			});
		},
		// called when the user clicks on the logout button
		logout() {
			if (cognitoUser) {
				cognitoUser.signOut();
				cognitoUser = undefined;
				localStorage.removeItem("authGroup");
			}
			return Promise.resolve();
		},
		// called when the API returns an error
		checkError(error: any) {
			console.warn("checkError", error);
			if ("message" in error && error.message === "User not authenticated") {
				localStorage.removeItem("authGroup");
				if (cognitoUser) {
					cognitoUser.signOut();
					cognitoUser = undefined;
				}
				return Promise.reject(new Error());
			}
			// if (status === 401 || status === 403) {
			// 	if (cognitoUser) {
			// 		cognitoUser.signOut();
			// 	}
			// 	return Promise.reject();
			// }
			return Promise.resolve();
		},
		getIdentity,
		// called when the user navigates to a new location, to check for authentication
		checkAuth() {
			if (localStorage.getItem("authGroup")) {
				return Promise.resolve();
			}
			return Promise.reject(new Error("No auth group"));
		},
		// called when the user navigates to a new location, to check for permissions / roles
		getPermissions() {
			// Hate that we're caching on localstorage, but the code seems to depend
			// on this working this way
			// const raw = localStorage.getItem("permissions");
			// if (raw === undefined || raw === null) {
			// 	return Promise.resolve([]);
			// }
			// return Promise.resolve(JSON.parse(raw));

			const authGroup = localStorage.getItem("authGroup");
			if (!authGroup) {
				return Promise.resolve([]);
			}

			switch (authGroup) {
				case "super-admin":
					return Promise.resolve(["*"]);
				case "inventory":
					return Promise.resolve(["inventory"]);
				default:
					console.error("Unknown group");
					return Promise.resolve([]);
			}

			// console.log("getPermissions", fetchedUser);
			// if (!fetchedUser?.adminUser) {
			// 	return Promise.resolve([])
			// }

			// switch (fetchedUser.adminUser.group) {
			// 	case "super-admin":
			// 		console.log(" perm ret super-admin")
			// 		return Promise.resolve(["*"])
			// 	case "inventory":
			// 		return Promise.resolve(["inventory"])
			// 	default:
			// 		throw new Error("Unknown group")
			// }
		},
	};
}

export { createAuthProvider };
