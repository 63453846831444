import React, { useState } from "react";
import { Button, Title } from "react-admin";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import type { Container, PaletteColour } from "./data.ts";

type TabId = "sold" | "onHand" | "loss";

type BaseInventoryReportProps = {
	readonly title: string;
	readonly onHandReportComponent: React.ComponentType<any>;
	readonly itemsSoldReportComponent: React.ComponentType<any>;
	readonly lossReportComponent: React.ComponentType<any>;
	readonly colours: readonly PaletteColour[];
	readonly containers: readonly Container[];
};

function BaseInventoryReport({
	title,
	onHandReportComponent: OnHandReport,
	itemsSoldReportComponent: ItemsSoldReport,
	lossReportComponent: LossReport,
	colours,
	containers,
}: BaseInventoryReportProps) {
	const tabs = [
		{
			id: "onHand" as TabId,
			component: OnHandReport,
			label: "Inventory on-hand",
		},
		{ id: "sold" as TabId, component: ItemsSoldReport, label: "Items sold" },
		{ id: "loss" as TabId, component: LossReport, label: "Damages/Loss" },
	];
	const [tab, setTab] = useState<TabId>("onHand");
	const TabContent = tabs.find((t) => t.id === tab)?.component;

	return (
		<div>
			<Title title={title} />
			{tabs.map((t) => (
				<Button
					key={t.id}
					type="button"
					onClick={() => setTab(t.id)}
					value={t.id}
					variant={t.id === tab ? "outlined" : undefined}
					color={t.id === tab ? "primary" : undefined}
				>
					<>{t.label}</>
				</Button>
			))}

			{TabContent && (
				<Card>
					<CardContent>
						<TabContent colours={colours} containers={containers} />
					</CardContent>
				</Card>
			)}
		</div>
	);
}

export default BaseInventoryReport;
