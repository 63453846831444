import React, { useState } from "react";
import type { ResourceComponentProps } from "react-admin";
import {
	Datagrid,
	DateField,
	FunctionField,
	List,
	EditButton,
	Button,
} from "react-admin";
import LoadMorePagination from "~/components/load-more-pagination.tsx";
import useInventoryData from "../inventory-shared/use-inventory-data.ts";
import InventoryItemTypeSummary from "./shared-components.tsx";

function BulkInventoryScanList(props: ResourceComponentProps) {
	const {
		colours,
		// containers,
	} = useInventoryData();
	const [open, setOpen] = useState<Record<string, boolean>>({});

	return (
		<List
			{...props}
			title="Bulk inventory scan"
			bulkActionButtons={false}
			sort={{ field: "createdAt", order: "DESC" }}
			pagination={<LoadMorePagination />}
		>
			<Datagrid>
				<DateField source="createdAt" showTime sortable={false} />
				<FunctionField
					source="status"
					sortable={false}
					render={({ status }: any) => {
						switch (status.type) {
							case "approved":
								return `Approved (as ${status.applyType})`;
							case "rejected":
								return "Rejected";
							default:
								return "Pending";
						}
					}}
				/>
				<FunctionField
					source="items"
					sortable={false}
					render={({ id, items }: any) => {
						const maxShown = 3;
						const countIsOpen = items.length <= maxShown || open[id];
						const visibleItems = countIsOpen ? items : items.slice(0, maxShown);
						return (
							<>
								<ul>
									{visibleItems.map((i: any) => (
										<li key={i.barcode}>
											{i.barcode} - {i.scanCount} x {i.unitsPerScan} x{" "}
											<InventoryItemTypeSummary
												itemType={i.itemType}
												colours={colours}
											/>
										</li>
									))}
								</ul>
								{!countIsOpen && (
									<Button
										variant="text"
										size="small"
										onClick={() => setOpen({ ...open, [id]: true })}
										label={`Show all (${items.length})`}
									/>
								)}
							</>
						);
					}}
				/>
				<FunctionField
					source="id"
					label="Action"
					sortable={false}
					render={(record: any) => {
						const { status } = record;
						if (status.type !== "pending") {
							return null;
						}
						return <EditButton label="Review" record={record} />;
					}}
				/>
			</Datagrid>
		</List>
	);
}

export { BulkInventoryScanList };
