import React from "react";
import { useQuery, useMutation } from "@apollo/client/react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
	Title,
	SimpleForm,
	required,
	useNotify,
	BooleanInput,
} from "react-admin";
import type { GetInstructionSettingsQuery } from "~/api/get-instruction-settings-query.generated.ts";
import getInstructionSettingsQuery from "~/api/get-instruction-settings-query.graphql";
import removeTypenames from "~/utils/remove-typenames.ts";
import updateInstructionSettingsMutation from "~/api/update-instruction-settings-mutation.graphql";
import SignedUploadInput from "~/components/signed-upload-input.tsx";
import FileLinkUrlField from "~/components/file-link-url-field.tsx";
import type {
	UpdateInstructionSettingsMutation,
	UpdateInstructionSettingsMutationVariables,
} from "~/api/update-instruction-settings-mutation.generated.ts";

function EditInstructionSettings() {
	const notify = useNotify();

	const { data: instructionSettingsData } =
		useQuery<GetInstructionSettingsQuery>(getInstructionSettingsQuery);

	const [saveInstructionSettings, saveInstructionSettingsResult] = useMutation<
		UpdateInstructionSettingsMutation,
		UpdateInstructionSettingsMutationVariables
	>(updateInstructionSettingsMutation, {
		onCompleted() {
			notify("Instruction settings updated", "info");
		},
		onError(e) {
			notify(e.toString(), { type: "error" });
		},
	});

	const onSave = async (variables: any) => {
		await saveInstructionSettings({
			variables,
		});
	};

	const instructionSettings = (() => {
		if (!instructionSettingsData) {
			return undefined;
		}

		return {
			...instructionSettingsData.instructionSettings,
		};
	})();

	return (
		<Card>
			<Title title="Instruction Settings" />
			<CardContent>
				{instructionSettings ? (
					<SimpleForm
						initialValues={removeTypenames(instructionSettings)}
						save={onSave}
						saving={saveInstructionSettingsResult.loading}
					>
						<SignedUploadInput
							source="catalogueImage"
							directory="instruction-settings"
							validate={[required()]}
						>
							<FileLinkUrlField />
						</SignedUploadInput>
						<BooleanInput source="catalogueEnabled" />
					</SimpleForm>
				) : (
					<div>Loading</div>
				)}
			</CardContent>
		</Card>
	);
}

export { EditInstructionSettings };
