import React from "react";

type FileLinkUrlFieldProps = {
	readonly className?: string;
	readonly record?: string;
};

function FileLinkUrlField({ record, className }: FileLinkUrlFieldProps) {
	const fileNameComps = record?.split("/") ?? [];
	return (
		<a
			className={className}
			href={record}
			rel="noopeneer noreferrer"
			target="_blank"
		>
			{fileNameComps[fileNameComps.length - 1]}
		</a>
	);
}

export default FileLinkUrlField;
