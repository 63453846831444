import { sum } from "lodash-es";
import type {
	AdminInventoryState,
	AdmininventorystateItems,
} from "~/api/types.generated.ts";

function calculateItemTotalCost(item: AdmininventorystateItems): number {
	return sum(
		item.history.map(
			(h) =>
				h.quantity *
				(h.cost === undefined || h.cost === null ? 0 : parseFloat(h.cost)),
		),
	);
}

function calculateStateTotalCost(state: AdminInventoryState): number {
	return sum(state.items.map(calculateItemTotalCost));
}

export { calculateItemTotalCost, calculateStateTotalCost };
