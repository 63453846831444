import type { QueryHookOptions } from "@apollo/client/react";
import { useQuery } from "@apollo/client/react";
import { useNotify } from "react-admin";
import { gql } from "@apollo/client";

import type {
	GetInventoryWeightsQuery,
	GetInventoryWeightsQueryVariables,
} from "~/api/get-inventory-weights-query.generated";

import getInventoryWeightsQuery from "~/api/get-inventory-weights-query.graphql";
import fullInventoryWeightsFragment from "~/api/full-inventory-weights-fragment.graphql";

function useInventoryWeights(
	options?: Omit<
		QueryHookOptions<
			GetInventoryWeightsQuery,
			GetInventoryWeightsQueryVariables
		>,
		"variables"
	>,
) {
	const notify = useNotify();

	const { data, loading } = useQuery<
		GetInventoryWeightsQuery,
		GetInventoryWeightsQueryVariables
	>(
		gql`
			${getInventoryWeightsQuery}
			${fullInventoryWeightsFragment}
		`,
		{
			onError(e) {
				notify(e.toString(), { type: "error" });
			},
			...options,
		},
	);

	return {
		inventoryWeights: data?.inventoryWeights,
		loading,
	};
}

export default useInventoryWeights;
